import { CommonModule, NgIf, NgOptimizedImage } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Navigation } from 'app/core/navigation/navigation.types';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { UserComponent } from '../user/user.component';
import { SelectSobComponent } from '../select-sob/select-sob.component';
import { Select, Store } from '@ngxs/store';
import { AuthSelectors } from '@NgXs/authentication/selectors/auth.selectors';
import { LoginApiResponse } from '@shared/interfaces/user';
import { SelectSeasonComponent } from '@shared/components/select-season/select-season.component';
import { MobileScreen, SmallScreen } from '@NgXs/breakpoint/breakpoint.action';
import { UserProfileSelectors } from '../../../../modules/user-profile/state/user-profile.selector';
import { UserData } from '../../../../modules/user-profile/interfaces/user-profile.interface';
import { HasPermissionDirective } from '../../../permissions/directives/has-permissions.directive';
import { Permission } from '../../../permissions/enums/permissions.enum';
import { PermissionsService } from 'app/core/permissions/permissions.service';
import { FloatingLabelsComponent } from '@shared/components/floating-labels/floating-labels.component';

@Component({
  selector: 'classy-layout',
  templateUrl: './classy.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    FuseLoadingBarComponent,
    FuseVerticalNavigationComponent,
    UserComponent,
    SelectSobComponent,
    NgIf,
    MatIconModule,
    MatButtonModule,
    FuseFullscreenComponent,
    RouterOutlet,
    RouterModule,
    SelectSeasonComponent,
    NgOptimizedImage,
    HasPermissionDirective,
    FloatingLabelsComponent,
  ],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(HasPermissionDirective) hasPermissionDirective: HasPermissionDirective;
  isScreenSmall: boolean;
  isMobileScreen: boolean;
  isSideMenuClosed = true;
  showCoachMenuItem: boolean = true;
  navigation: Navigation = {
    default: [
      {
        id: 'seasons',
        title: 'Seasons',
        type: 'basic',
        icon: 'heroicons_outline:calendar-days',
        link: '/seasons',
        queryParamsHandling: 'preserve',
      },
      {
        id: 'opponents',
        title: 'Opponents',
        type: 'collapsable',
        icon: 'heroicons_outline:user-group',
        children: [
          {
            id: 'view-opponents',
            title: 'View Opponents',
            type: 'basic',
            link: '/opponents/view-opponents',
            queryParamsHandling: 'preserve',
          },
          {
            id: 'schedule',
            title: 'Schedule',
            type: 'basic',
            link: '/opponents/schedule',
            queryParamsHandling: 'preserve',
          },
        ],
      },
      {
        id: 'position',
        title: 'Position',
        type: 'basic',
        icon: 'heroicons_outline:identification',
        link: '/position',
        queryParamsHandling: 'preserve',
      },
      {
        id: 'coach',
        title: 'Coach',
        type: 'collapsable',
        icon: 'heroicons_outline:user-circle',
        children: [
          {
            id: 'coach',
            title: 'Show/Add Coach',
            type: 'basic',
            link: '/coach',
            queryParamsHandling: 'preserve',
            hidden: (item) => this.showCoachMenuItem,
          },
          {
            id: 'guest',
            title: 'Show/Add Guest',
            type: 'basic',
            link: '/guest',
            queryParamsHandling: 'preserve',
          },
        ],
      },
      {
        id: 'roster',
        title: 'Roster',
        type: 'collapsable',
        icon: 'heroicons_outline:user',
        children: [
          {
            id: 'player',
            title: 'Player',
            type: 'basic',
            link: '/player',
            queryParamsHandling: 'preserve',
          },
          {
            id: 'player-substitution',
            title: 'Player Substitution',
            type: 'basic',
            link: '/player-substitution',
            queryParamsHandling: 'preserve',
          },
        ],
      },
      {
        id: 'production-items',
        title: 'Production',
        type: 'basic',
        icon: 'mat_outline:list_alt',
        link: '/production-items',
        queryParamsHandling: 'preserve',
        hidden: () => !this.permissionsService.Permissions.includes(Permission.ViewMtpTypes),
      },
      {
        id: 'show-matches',
        title: 'Grade',
        type: 'basic',
        icon: 'mat_outline:assessment',
        link: '/show-matches',
        queryParamsHandling: 'preserve',
      },
      {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar-square',
        link: '/reports',
        queryParamsHandling: 'preserve',
      },
      {
        id: 'data-import',
        title: 'Data Import',
        type: 'collapsable',
        icon: 'mat_outline:cloud_upload',
        children: [
          {
            id: 'import-grading-data',
            title: 'Grading Data',
            type: 'basic',
            link: '/data-import/import-grading-data',
            queryParamsHandling: 'preserve',
          },
          {
            id: 'import-scouting-data',
            title: 'Opponent Scouting Data',
            type: 'basic',
            link: '/data-import/import-scouting-data',
            queryParamsHandling: 'preserve',
            hidden: () =>
              !this.permissionsService.Permissions.includes(Permission.ViewScoutingReports),
          },
        ],
      },
      {
        id: 'map-grading-data',
        title: 'Map Data',
        type: 'basic',
        icon: 'mat_outline:compare_arrows',
        link: '/data-import/map-grading-data',
        queryParamsHandling: 'preserve',
      },
      {
        id: 'help-center',
        title: 'Help Center',
        type: 'basic',
        icon: 'mat_outline:live_help',
        link: '/help-center',
        queryParamsHandling: 'preserve',
      },
      {
        id: 'system-settings',
        title: 'System Settings',
        type: 'basic',
        icon: 'mat_outline:settings',
        link: '/system-settings',
        queryParamsHandling: 'preserve',
      },
      {
        id: 'infinite-athlete',
        title: 'Infinite Athlete',
        type: 'basic',
        icon: 'heroicons_outline:film',
        link: '/infinite-athlete',
        queryParamsHandling: 'preserve',
        hidden: () => !this.permissionsService.Permissions.includes(Permission.ViewInfiniteAthlete),
      },
    ],
  };

  @Select(AuthSelectors.currentUser)
  currentUser$: Observable<LoginApiResponse | null>;
  @Select(UserProfileSelectors.getUserProfileState) userData$: Observable<UserData | null>;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private store: Store,
    private router: Router,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private cdr: ChangeDetectorRef,
    private permissionsService: PermissionsService
  ) {}

  ngAfterViewInit(): void {
    this.hasPermissionDirective.hasPermission = Permission.ViewCoach;
    this.showCoachMenuItem = !this.hasPermissionDirective.checkAllPermissions();
    this.navigation = { ...this.navigation };

    this.cdr.detectChanges();
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }

  ngOnInit(): void {
    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        this.isScreenSmall = !matchingAliases.includes('md');

        this.store.dispatch(new SmallScreen(this.isScreenSmall));

        this.isMobileScreen = !matchingAliases.includes('xsm');
        this.store.dispatch(new MobileScreen(this.isMobileScreen));

        this.isSideMenuClosed = !this.isScreenSmall;
      });
  }

  home() {
    this.router.navigate(['/dashboard'], { queryParamsHandling: 'preserve' });
    this.openNavigation('mainNavigation');
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  openNavigation(name: string): void {
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
    this.isSideMenuClosed = true;
    if (navigation) navigation.open();
  }

  closeNavigation(name: string): void {
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
    this.isSideMenuClosed = false;
    if (navigation) navigation.close();
  }

  toggleNavigation(name: string): void {
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
    this.isSideMenuClosed = !this.isSideMenuClosed;
    if (navigation) navigation.toggle();
  }
}
