import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { GradingData, GradingMatch } from '../interfaces/grading.interface';
import { PlayerMatchI } from 'app/modules/show-matches/interfaces/show-matches.interface';
import { PositionGroup } from '@shared/interfaces/position-group.interface';

export class RefreshGradingScreen {
  static readonly type = '[Grading] Refresh Grading Screen';
  constructor(public state: boolean) {}
}

export class GetGradingMatch {
  static readonly type = '[Grading] Grading Match';
  constructor(public matchId: GradingMatch['id']) {}
}
export class UpdateGradingMatch {
  static readonly type = '[Grading] Update Grading Match';
  constructor(public match: GradingMatch) {}
}

export class GetGradingData {
  static readonly type = '[Grading] Grading Data';
  constructor(
    public match: GradingMatch,
    public playerId: PlayerMatchI['id']
  ) {}
}

export class UpdateGradingData {
  static readonly type = '[Grading] Update Grading Data';
  constructor(public gradingData: GradingData[]) {}
}

export class ResetGradingState {
  static readonly type = '[Grading] Reset Grading State';
}

export class GradingSwapPlayer {
  static readonly type = '[Grading] Grading Swap Player';
  constructor(
    public player: PlayerMatchI,
    public payload
  ) {}
}

export class GradingDeletePlayer {
  static readonly type = '[Grading] Grading Delete Player';
  constructor(
    public playerId: PlayerMatchI['id'],
    public playerPg: PositionGroup['name']
  ) {}
}

export class GradingDeleteAllPlayers {
  static readonly type = '[Grading] Grading Delete All Players';
  constructor(
    public players: GradingData['players'],
    public playersPg: PositionGroup['name']
  ) {}
}

export class UpdateGradingPgCheck {
  static readonly type = '[Grading] Update Grading Position Group Check';
  constructor(public grade: GradingData) {}
}

export class UpdateGradingPlayerCheck {
  static readonly type = '[Grading] Update Grading Player Check';
  constructor(
    public grade: GradingData,
    public index: number
  ) {}
}

export class SetGradingBackLink {
  static readonly type = '[Grading] Set Grading Back Link';
  constructor(public link: string) {}
}

export class SetGradingVisibleMatches {
  static readonly type = '[Grading] Set Grading Visible Matches';
  constructor(public visibleMatches) {}
}

export class SetGradingVisiblePgs {
  static readonly type = '[Grading] Set Grading Visible Pgs';
  constructor(public pgs: PositionGroup['name'][]) {}
}

export class UpdateGradingVisiblePgs {
  static readonly type = '[Grading] Update Grading Visible Pgs';
  constructor(public pgs: PositionGroup['name'][]) {}
}

export class RemoveGradingVisiblePgs {
  static readonly type = '[Grading] Remove Grading Visible Pgs';
}

export class UpdateGradingPlayerScore {
  static readonly type = '[Grading] Update Grading Player Score';
  constructor(
    public skill,
    public payload
  ) {}
}

export class UpdateGradingPlayerSkillClass {
  static readonly type = '[Grading] Update Grading Player Skill Class';
  constructor(
    public score,
    public payload: {
      skill_class: number;
    }
  ) {}
}

export class UpdateGradingPlayer {
  static readonly type = '[Grading] Update Grading Player';
  constructor(
    public playerMatchId: PlayerMatchI['id'],
    public payload
  ) {}
}

export class UpdateGradingPlayerInStore {
  static readonly type = '[Grading] Update Grading Player In Store';
  constructor(public playerMatchId: PlayerMatchI['id']) {}
}

export class ResetGradingData {
  static readonly type = '[Grading] Reset Grading Data';
}
