import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CardComponent } from '@shared/components/card/card.component';
import { UserData } from './interfaces/user-profile.interface';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { NotificationService } from '@shared/services/notification/notification.service';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { Select, Store } from '@ngxs/store';
import { UserProfileSelectors } from './state/user-profile.selector';
import { catchError, finalize, Observable, Subject, takeUntil } from 'rxjs';
import {
  EditUserProfileAvatar,
  EditUserProfileDetails,
  LoadUserProfile,
} from './state/user-profile.action';
import { FuseValidators } from '@fuse/validators';
import { ChangePassword } from '@NgXs/authentication/actions/change-password.action';
import { StopLoading } from '@NgXs/loading/loading.action';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';

export function fileSizeCheck(file): boolean {
  const MAX_SIZE = 6 * 1024 * 1024;
  if (file.size > MAX_SIZE) {
    return false;
  }

  return true;
}
@Component({
  selector: 'og-user-profile',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatRippleModule,
    NgOptimizedImage,
    MatCheckboxModule,
    MatExpansionModule,
    MatSelectModule,
    MatSlideToggleModule,
  ],
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent implements OnInit, OnDestroy {
  @Select(UserProfileSelectors.getUserProfileState) userData$: Observable<UserData | null>;
  userData: UserData | null = null;
  isEditing = false;
  private avatar: File | '' = '';
  private destroy$ = new Subject<void>();
  private originalAvatar: string | null = null;
  userProfileForm: FormGroup;
  changePasswordForm: FormGroup;
  isPasswordChangeEnabled = new FormControl<boolean>(false);
  isAvatarSetNull = true;

  constructor(
    private store: Store,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private fb: FormBuilder
  ) {
    this.userProfileForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      username: ['', Validators.required],
    });
    this.changePasswordForm = this.fb.group(
      {
        old_password: ['', [Validators.required, Validators.minLength(8)]],
        new_password1: ['', [Validators.required, Validators.minLength(8)]],
        new_password2: ['', Validators.required],
      },
      {
        validators: FuseValidators.mustMatch('new_password1', 'new_password2'),
      }
    );
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadUserProfile()).subscribe(() => {
      this.getUserData();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getUserData(): void {
    this.userData$.pipe(takeUntil(this.destroy$)).subscribe((userData) => {
      this.userData = userData;
      if (userData) {
        this.userProfileForm.patchValue({
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          username: userData.username,
        });
        this.originalAvatar = userData.coach.avatar;
      }
      this.cdr.detectChanges();
    });
  }

  submit(): void {
    this.updateUserProfileDetails();
  }

  private uploadAvatarIfNeeded(): void {
    if (!this.avatar && !this.isAvatarSetNull) return;

    const formData = new FormData();
    formData.append('avatar', this.avatar);
    this.store
      .dispatch(new EditUserProfileAvatar(formData, this.userData?.coach.id))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.avatar = '';
          this.notificationService.success('Profile picture updated');
          this.cdr.detectChanges();
        },
        // error: (error) => this.notificationService.error('Error uploading profile picture'),
      });
  }

  private updateUserProfileDetails(): void {
    if (!this.userProfileForm.dirty) {
      this.isEditing = false;
      return;
    }
    this.store
      .dispatch(new EditUserProfileDetails(this.userProfileForm.value))
      .pipe(
        takeUntil(this.destroy$),
        catchError((err) => {
          this.notificationService.error('Error updating user details');
          return err;
        })
      )
      .subscribe({
        next: () => {
          this.isEditing = false;
          this.notificationService.success('User details updated successfully');
          this.cdr.detectChanges();
        },
        error: (error) => this.notificationService.error('Error updating user details'),
      });
  }

  uploadAvatar(event: Event | undefined): void {
    if (!event) {
      this.avatar = '';
      this.originalAvatar = this.userData?.coach.avatar ?? undefined;
      this.userData!.coach.avatar = undefined;
      this.isAvatarSetNull = true;
      this.uploadAvatarIfNeeded();
      this.cdr.detectChanges();
      return;
    }

    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files;

    if (!files || files.length === 0) return;
    if (!fileSizeCheck(files[0])) {
      fileInput.value = '';
      this.notificationService.error('Error occurred!', 'File size exceeds the limit of 6MB!');
      return;
    }

    this.avatar = files[0];
    this.originalAvatar = this.userData?.coach.avatar ?? null;
    this.isAvatarSetNull = false;
    this.userData!.coach.avatar = URL.createObjectURL(files[0]);
    this.uploadAvatarIfNeeded();
    this.cdr.detectChanges();
  }

  clearForm(): void {
    this.isEditing = false;
    if (this.userData) {
      this.userProfileForm.reset({
        first_name: this.userData.first_name,
        last_name: this.userData.last_name,
        email: this.userData.email,
        username: this.userData.username,
      });
      if (this.originalAvatar) {
        this.userData.coach.avatar = this.originalAvatar;
        this.originalAvatar = null;
      }
    }
    this.isPasswordChangeEnabled.setValue(false);
    this.changePasswordForm.reset();
    this.cdr.detectChanges();
  }

  editFields() {
    this.isEditing = true;
    this.cdr.detectChanges();
  }

  changePassword() {
    if (this.isPasswordChangeEnabled && this.changePasswordForm.valid) {
      let payload = this.changePasswordForm.value;
      this.store
        .dispatch(new ChangePassword(payload))
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (state) => {
            const { isSuccess, message } = state.auth.changePassword;
            if (isSuccess) {
              this.notificationService.success('New Password has been saved');
              Object.keys(this.changePasswordForm.controls).forEach((controlName) => {
                const control = this.changePasswordForm.get(controlName);
                if (control) {
                  control.setValue(null);
                  control.setErrors(null);
                }
              });
            } else {
              this.changePasswordForm.reset();
              this.notificationService.error(message || 'Error!');
            }
          },
          error: (error) => {
            this.changePasswordForm.reset();
            Object.keys(error).forEach((prop) => {
              const formControl = this.changePasswordForm.get(prop);
              if (formControl) {
                this.notificationService.error(error[prop][0] || 'Error!');
              }
            });
          },
          complete: () => {
            this.store.dispatch(new StopLoading());
          },
        });
    }
    this.isPasswordChangeEnabled.setValue(true);
  }

  home() {
    this.router.navigate(['/dashboard'], { queryParamsHandling: 'preserve' });
  }
}
