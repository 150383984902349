import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { CardComponent } from '@shared/components/card/card.component';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Select, Store } from '@ngxs/store';
import { SeasonSelectors } from '@shared/states/season/season.selector';
import { Observable, Subject, combineLatest, finalize, takeUntil } from 'rxjs';
import { Season } from '@shared/interfaces/season.interface';
import { PillsRendererComponent } from './components/pills-renderer/pills-renderer.component';
import { SobSelectors } from '../../core/layout/components/select-sob/state/sob.selector';
import { SelectWeekComponent } from '@shared/components/select-week/select-week.component';
import { WeeksSelector } from '@shared/states/weeks/weeks.selector';
import { WeeksApi } from '@shared/interfaces/weeks.interface';
import { SelectGamePracticeComponent } from '@shared/components/select-game-practice/select-game-practice.component';
import { GamePracticeSelectors } from '@shared/states/game-practice/game-practice.selector';
import { DeletePlayerMatch, LoadMatches } from './state/matches.action';
import { AgGridModule } from 'ag-grid-angular';
import { ButtonsRendererComponent } from './components/buttons-renderer/buttons-renderer.component';
import { MatButtonModule } from '@angular/material/button';
import { QueryParamsService } from '@shared/services/queryParams/query-params.service';
import { MatDialog } from '@angular/material/dialog';
import { AddPlayerDialogComponent } from '@shared/components/dialogs/add-player-dialog/add-player-dialog.component';
import { GradeSheetDialogComponent } from '@shared/components/dialogs/grade-sheet-dialog/grade-sheet-dialog.component';
import { GradingDataPayload, MatchData } from './interfaces/show-matches.interface';
import { StartLoading, StopLoading } from '@NgXs/loading/loading.action';
import { LoadingSelectors } from '@NgXs/loading/loading.selectors';
import { StorageMediumSelector } from '@shared/states/storage-medium/storage-medium.selector';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { ResetSelectedWeek } from '@shared/states/weeks/weeks.action';
import {
  ResetSelectedGameType,
  SetSelectedGameType,
} from '@shared/states/game-practice/game-practice.action';
import { MatchesSelectors } from './state/matches.selector';
import {
  ResetGradingState,
  SetGradingBackLink,
  SetGradingVisibleMatches,
  SetGradingVisiblePgs,
} from '../grading/state/grading.actions';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Sob } from 'app/core/layout/components/select-sob/enums/sob.enum';
import { MatIconModule } from '@angular/material/icon';
import { GradeSheetPreviewComponent } from './components/grade-sheet-preview/grade-sheet-preview.component';
import { AlertTypes } from '@shared/enums/alert/alert.enum';
import { AlertService } from '@shared/services/alert/alert.service';
import { NotificationService } from '@shared/services/notification/notification.service';
import { PermissionsService } from '../../core/permissions/permissions.service';
import { SelectPositionGroupDialogComponent } from '@shared/components/select-position-group-dialog/select-position-group-dialog.component';
import { ShowMatchesService } from './services/show-matches.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'og-show-matches',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    SelectWeekComponent,
    SelectGamePracticeComponent,
    GradeSheetPreviewComponent,
    AgGridModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
  ],
  templateUrl: './show-matches.component.html',
  styleUrls: ['./show-matches.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowMatchesComponent implements OnInit, OnDestroy {
  @Select(LoadingSelectors.isLoading)
  isLoading$: Observable<boolean>;
  @Select(SeasonSelectors.selectedSeason)
  currentSeason$: Observable<Season | null>;
  @Select(SobSelectors.selectedSob)
  currentSob$: Observable<SOB | null>;
  @Select(WeeksSelector.selectedWeek)
  selectedWeek$: Observable<WeeksApi | null>;
  @Select(GamePracticeSelectors.getSelectedGameType)
  selectedPlayType$: Observable<{
    playType: string;
    countNo: number;
    gp_count: number;
    qtr: number;
    type: string;
  } | null>;
  @Select(StorageMediumSelector.storageMedium) storageMedium$: Observable<Storage | null>;

  private destroy$ = new Subject<void>();

  rowData = [];

  gridOptions: GridOptions = {
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your data is loading</span>',
    overlayNoRowsTemplate: `<span class="ag-overlay-loading-center">Please select a week and play type to get data</span>`,
    suppressAutoSize: true,
    suppressMovableColumns: true,
    suppressHorizontalScroll: false,
  };
  gridApi: GridApi;

  seasonId: Season['id'];
  selectedSob: SOB;
  currentWeek: WeeksApi;
  gameType: {
    playType: string;
    countNo: number;
    gp_count: number;
    qtr: number;
    type: string;
  };
  periodDescriptions: { id: number; value: string }[] = [];
  phaseTypes: string[] = [];
  storageMedium: Storage;
  isSpecialTeams = false;

  productionList: string[] = [];
  skillSet: string[] = [];
  numberOfSheets: number;
  selectedPositionNames: string[] = [];
  positionGroup: string;
  noPlayIsGraded: boolean = false;

  get canDelete() {
    return this.permissionsService.Permissions.includes('delete_matches');
  }

  get columnDefs() {
    return [
      {
        field: 'play',
        headerName: 'Play #',
        headerClass: 'p-0',
        rowDrag: false,
        pinned: true,
        minWidth: 60,
        width: 60,
        flex: 1,
      },
      {
        field: this.isPractice ? 'quarter_description' : 'play_pgs_players',
        headerName: this.isPractice ? 'Period Desc.' : 'Players',
        cellDataType: false,
        headerClass: this.isPractice ? '' : 'p-0',
        minWidth: 130,
        flex: 1,
        resizable: true,
        cellStyle: (params: any) => {
          return params.data && params.data.players > 11 ? { color: 'red' } : null;
        },
      },
      {
        field: 'types',
        headerName: 'Type',
        headerClass: 'p-0',
        minWidth: 60,
        flex: 1,
        resizable: true,
        cellRenderer: PillsRendererComponent,
      },
      {
        field: 'qtr',
        headerName: this.isPractice ? 'PER' : 'QTR',
        headerClass: 'p-0',
        flex: 1,
        minWidth: 45,
      },
      {
        field: 'down',
        headerName: 'Down',
        headerClass: 'p-0',
        flex: 1,
        minWidth: 45,
      },
      {
        field: 'dist',
        headerName: 'Dist',
        headerClass: 'p-0',
        flex: 1,
        minWidth: 45,
      },
      {
        field: 'fpos',
        headerName: 'Fpos',
        headerClass: 'p-0',
        flex: 1,
        minWidth: 45,
      },
      {
        field: 'hash',
        headerName: 'Hash',
        headerClass: 'p-0',
        flex: 1,
        minWidth: 45,
      },
      {
        field: 'play_call',
        headerName: 'Play call',
        headerClass: 'left-aligned',
        minWidth: 120,
        flex: 1,
        resizable: true,
        cellStyle: {
          'justify-content': 'flex-start',
        },
      },
      {
        field: 'opp_play',
        headerName: 'Opp Play',
        headerClass: 'left-aligned',
        minWidth: 105,
        flex: 1,
        resizable: true,
        cellStyle: {
          'justify-content': 'flex-start',
        },
      },
      {
        headerName: this.canDelete ? '(View | Grade | Delete)' : '(View | Grade)',
        minWidth: 200,
        flex: 1,
        cellRenderer: ButtonsRendererComponent,
        cellRendererParams: {
          onClick: this.showButton.bind(this),
          onDelete: this.onDelete.bind(this),
        },
      },
    ];
  }

  get isPractice(): boolean {
    return this.gameType?.playType == 'P';
  }

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private gsdialog: MatDialog,
    private location: Location,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private queryParamsService: QueryParamsService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private showMatchesService: ShowMatchesService,
    private permissionsService: PermissionsService
  ) {
    this.store.dispatch(new ResetGradingState());

    this.storageMedium$.pipe(takeUntil(this.destroy$)).subscribe((storageMedium: any) => {
      this.storageMedium = storageMedium;
    });

    let queryParams = this.route.snapshot.queryParams;
    let queryParamsToRemove = {};
    for (const key in queryParams) {
      if (
        key != 'count' &&
        key != 'gp_count' &&
        key != 'matchType' &&
        key != 'weekNo' &&
        key != 'sob' &&
        key != 'season'
      ) {
        queryParamsToRemove[key] = null;
      }
    }

    if (_.size(queryParamsToRemove)) {
      this.queryParamsService.removeQueryParams(queryParamsToRemove);
    }
  }

  ngOnInit(): void {
    combineLatest([this.currentSob$, this.currentSeason$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([sob, season]) => {
        if (this.selectedSob && this.seasonId) {
          this.currentWeek = null;
          this.store.dispatch(new ResetSelectedWeek());
          this.clearData();
        }

        this.seasonId = season.id;
        this.selectedSob = sob;
        this.isSpecialTeams = sob.value == Sob.SpecialTeamsValue;
      });

    this.selectedWeek$.pipe(takeUntil(this.destroy$)).subscribe((week) => {
      if (this.currentWeek?.pk) this.clearData();
      this.currentWeek = week;

      this.cdr.detectChanges();
    });

    this.selectedPlayType$.pipe(takeUntil(this.destroy$)).subscribe((gameType) => {
      if (this.isPractice && gameType.countNo != this.gameType.countNo) {
        gameType.qtr = null;
        this.periodDescriptions = [];

        gameType.type = null;
        this.phaseTypes = [];
      }

      this.gameType = gameType;
      if (this.currentWeek?.pk && this.gameType?.playType) this.getRowData();

      this.cdr.detectChanges();
    });
  }

  clearData() {
    this.rowData = [];
    this.periodDescriptions = [];
    this.phaseTypes = [];

    // putting it in setTimeout is required because it was blocking the positionGroup api to call on sobchange
    setTimeout(() => {
      this.queryParamsService.removeQueryParams({
        count: null,
        gp_count: null,
        matchType: null,
        weekNo: null,
      });
    }, 0);

    this.cdr.detectChanges();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  applyCellStyle() {
    return {
      color: '#0a0a49',
      'font-size': '15px',
    };
  }

  getRowData() {
    this.rowData = [];
    this.store.dispatch(new StartLoading());
    this.store
      .dispatch(
        new LoadMatches(
          this.seasonId,
          this.currentWeek?.week_no,
          this.gameType?.countNo,
          this.gameType?.playType,
          this.selectedSob.alias,
          this.gameType?.qtr,
          this.gameType?.type
        )
      )
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.store.dispatch(new StopLoading()))
      )
      .subscribe((state) => {
        this.rowData = state.matches.matches;
        const matchesData = this.rowData;
        this.noPlayIsGraded = matchesData.every((item) => item.play_pgs_players === 0);
        this.changeUrlToCustomizedUrl();
        if (!this.gameType?.qtr && !this.isSpecialTeams) this.setPeriodDescriptions();
        if (!this.gameType?.type && this.isSpecialTeams) this.setPhaseTypes();

        this.cdr.detectChanges();
      });
  }

  setPeriodDescriptions(): void {
    this.periodDescriptions = [];

    if (this.isPractice && this.gameType?.countNo) {
      this.rowData.forEach((match: MatchData) => {
        this.periodDescriptions.push({ id: match.qtr, value: match.period_and_description });
      });

      this.periodDescriptions = _.uniqBy(this.periodDescriptions, 'id');
    }
  }

  setPhaseTypes(): void {
    this.phaseTypes = [];
    this.rowData.forEach((match: MatchData) => {
      this.phaseTypes.push(match.types);
    });

    this.phaseTypes = _.uniq(this.phaseTypes);
  }

  filterByPeriodDescription(periodDescription: number): void {
    this.store.dispatch(
      new SetSelectedGameType(
        this.gameType?.playType,
        this.gameType?.countNo,
        this.gameType?.gp_count,
        periodDescription,
        this.gameType?.type
      )
    );
  }

  filterByPhaseTypes(type: string): void {
    this.store.dispatch(
      new SetSelectedGameType(
        this.gameType?.playType,
        this.gameType?.countNo,
        this.gameType?.gp_count,
        this.gameType?.qtr,
        type
      )
    );
  }

  changeUrlToCustomizedUrl(): void {
    if (
      this.seasonId &&
      this.selectedSob.alias &&
      this.currentWeek?.week_no != null &&
      this.currentWeek?.week_no != undefined &&
      this.gameType?.gp_count
    ) {
      const count = this.gameType.countNo;
      const gp_count = this.gameType.gp_count;
      const matchType = this.gameType.playType;
      const weekNo = this.currentWeek.week_no;
      this.queryParamsService.updateQueryParams({
        count,
        gp_count,
        matchType,
        weekNo,
      });
    }
  }

  showButton(match: MatchData, mode: string) {
    switch (mode) {
      case 'view':
        const dialog = this.dialog.open(SelectPositionGroupDialogComponent, {
          width: '320px',
          disableClose: true,
        });
        dialog.afterClosed().subscribe((pgs) => {
          if (pgs) {
            this.setPlayNumbers();
            this.store.dispatch(new SetGradingBackLink(this.location.path()));
            this.store.dispatch(new SetGradingVisiblePgs(pgs.map((pg) => pg.name)));

            this.store.dispatch(new ResetSelectedWeek());
            this.store.dispatch(new ResetSelectedGameType());

            this.queryParamsService.updateQueryParams(
              {
                count: null,
                gp_count: null,
                matchType: null,
                weekNo: null,
                mode: 'd',
                activity: 'v',
              },
              ['/grading', 'grade-controller', match.id]
            );
          }
        });

        break;

      case 'edit':
        let videoObj = JSON.parse(localStorage.getItem('videoData'));

        localStorage.setItem(
          'videoData',
          JSON.stringify({
            gameId: match.external_game_id,
            playNo: match.play,
            selectedAngle: videoObj != null ? videoObj.selectedAngle : 0,
          })
        );

        this.addPlayer(match);
        break;
    }
  }

  addPlayer(match: MatchData): void {
    this.setPlayNumbers();

    const dialog = this.dialog.open(AddPlayerDialogComponent, {
      width: '830px',
      data: {
        match: match,
        sobValue: this.selectedSob.value,
        seasonId: this.seasonId,
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe((res) => {
      if (!res) {
        this.storageMedium.removeItem('visibleMatches');
      }
    });
  }

  exportGradingData(): void {
    const payload: GradingDataPayload = {
      season_id: this.seasonId,
      side_of_ball: this.selectedSob.value,
      week_id: this.currentWeek.pk,
      game_practice_count: this.gameType.countNo,
      game_practice_id: this.gameType.gp_count,
    };
    this.showMatchesService.getPlayerGradingData(payload).subscribe((response) => {
      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, 'OG Data Export.xlsx');
    });
  }

  openGradeSheetDialog(): void {
    const dialog = this.gsdialog.open(GradeSheetDialogComponent, {
      width: '830px',
      disableClose: true,
    });

    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.selectedPositionNames = res.positions;
        this.skillSet = res.skills;
        this.productionList = res.productionList;
        this.numberOfSheets = res.sheetCount;
        this.cdr.detectChanges();

        setTimeout(() => {
          document.title = 'Grade Sheets';
          window.print();
        });
        setTimeout(() => {
          document.title = 'Optimum Grading';
        }, 1000);
      }
    });
  }

  setPlayNumbers(): void {
    let playDetails: {
      qtr: number | null;
      play: number;
      id: number;
      quarter_description: string;
    }[] = [];
    this.rowData.forEach((play: any) => {
      playDetails.push({
        qtr: play?.qtr,
        play: play?.play,
        id: play?.id,
        quarter_description: this.isSpecialTeams
          ? play?.types
          : this.isPractice
            ? play?.quarter_description
            : null,
      });
    });

    this.store.dispatch(new SetGradingVisibleMatches(playDetails));
  }
  optionChange(event?) {
    if (event) event.source.close();
  }

  onDelete(match: MatchData) {
    if (match && match.id) {
      this.alertService
        .show(AlertTypes.Warning, 'Confirmation', 'Are you sure you want to delete this play?')
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: boolean) => {
          if (result) {
            this.store.dispatch(new StartLoading());
            this.store
              .dispatch(new DeletePlayerMatch(match.id))
              .pipe(
                takeUntil(this.destroy$),
                finalize(() => this.store.dispatch(new StopLoading()))
              )
              .subscribe(() => {
                this.rowData = this.rowData.filter((item: MatchData) => item.id !== match.id);
                this.notificationService.success('Play deleted successfully');
                this.cdr.detectChanges();
              });
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.queryParamsService.removeQueryParams({
      count: null,
      gp_count: null,
      matchType: null,
      weekNo: null,
    });
  }
}
