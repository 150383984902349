import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { CoachData } from '../../user-profile/interfaces/user-profile.interface';
import { environment } from '../../../../environments/environment';
import { CoachPayload, PatchCoachApiRes } from '../interfaces/coach.interface';
import { Season } from '@shared/interfaces/season.interface';
import { PositionGroup } from '../../roster/interfaces/roster.types';
import { LoginApiResponse, User } from '@shared/interfaces/user';
import { AuthSelectors } from '@NgXs/authentication/selectors/auth.selectors';
import { Select } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class CoachService {
  sessionUserId: User['pk'];

  editModeSource = new BehaviorSubject<boolean>(false);
  editMode$ = this.editModeSource.asObservable();

  @Select(AuthSelectors.currentUser) currentUser$: Observable<LoginApiResponse | null>;

  constructor(private http: HttpClient) {
    this.currentUser$.subscribe((sessionUser: LoginApiResponse) => {
      if (sessionUser?.user?.pk) {
        this.sessionUserId = sessionUser.user.coach_info.find(
          (c) => c.User == sessionUser.user.pk
        ).parent_account;
      }
    });
  }

  getCoaches(): Observable<CoachData[]> {
    return this.http
      .get<CoachData[]>(`${environment.main_url}/Coach/`)
      .pipe(
        map((coaches) =>
          coaches.sort((a, b) => a.coach_info.first_name.localeCompare(b.coach_info.first_name))
        )
      );
  }
  getCoachById(id: CoachData['id']): Observable<CoachData> {
    return this.http.get<CoachData>(`${environment.main_url}/Coach/${id}/`);
  }
  postCoach(payload: CoachPayload): Observable<string> {
    return this.http.post<string>(`${environment.main_url}/User/register_view/`, payload);
  }
  patchCoach(payload: CoachPayload, id: CoachData['User']): Observable<PatchCoachApiRes> {
    return this.http.patch<PatchCoachApiRes>(`${environment.main_url}/User/${id}/`, payload);
  }
  deleteCoach(id: CoachData['User']): Observable<null> {
    return this.http.delete<null>(`${environment.main_url}/User/${id}/`);
  }
  deleteAllCoaches(ids: CoachData['User'][]): Observable<null> {
    return this.http.delete<null>(`${environment.main_url}/User/${ids}/`);
  }

  setEditMode(isEditMode: boolean) {
    this.editModeSource.next(isEditMode);
  }
}
