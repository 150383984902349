import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, finalize, forkJoin, takeUntil } from 'rxjs';
import { MatchData } from 'app/modules/show-matches/interfaces/show-matches.interface';
import { SOB } from 'app/core/layout/components/select-sob/interfaces/sob.interface';
import { StartLoading, StopLoading } from '@NgXs/loading/loading.action';
import { Season } from '@shared/interfaces/season.interface';
import { PositionGroup } from '@shared/interfaces/position-group.interface';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Player, PlayerSubstituion, Position } from '@shared/interfaces/player';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { ShowMatchesService } from 'app/modules/show-matches/services/show-matches.service';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { PlayerMatchGridComponent } from 'app/modules/show-matches/components/player-match-grid/player-match-grid.component';
import { MultiSelectPositionGroupsComponent } from '@shared/components/multi-select-position-groups/multi-select-position-groups.component';
import { PositionGroupSelectors } from '@shared/states/position-group/position-group.selectors';
import {
  RefreshGradingScreen,
  SetGradingBackLink,
  SetGradingVisiblePgs,
  UpdateGradingVisiblePgs,
} from 'app/modules/grading/state/grading.actions';
import { QueryParamsService } from '@shared/services/queryParams/query-params.service';
import { LoadingSelectors } from '@NgXs/loading/loading.selectors';
import { StorageMediumSelector } from '@shared/states/storage-medium/storage-medium.selector';
import { GradingSelectors } from 'app/modules/grading/state/grading.selectors';
import { Sob } from 'app/core/layout/components/select-sob/enums/sob.enum';
import { SelectPositionGroupComponent } from '@shared/components/select-position-group/select-position-group.component';
import _ from 'lodash';
// import { PlayerMatch } from 'app/modules/show-matches/state/matches.action';

@Component({
  selector: 'og-add-player-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    PlayerMatchGridComponent,
    SelectPositionGroupComponent,
    MultiSelectPositionGroupsComponent,
  ],
  templateUrl: './add-player-dialog.component.html',
  styleUrls: ['./add-player-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPlayerDialogComponent implements OnInit, OnDestroy {
  listedPlayers = [];
  selectedPlayers = [];
  selectedPgs: PositionGroup[] = [];
  players: Player[] = [];
  substitutionPlayers: Player[] = [];
  selectedSubstitution!: PlayerSubstituion;
  substitutionList = [];
  inputSkeletonRange: number[] = Array.from({ length: 3 }, (_, i) => i + 1);

  storageMedium: Storage;

  protected readonly Sob = Sob;
  private unsubscribeAll: Subject<any> = new Subject<any>();

  @Select(LoadingSelectors.isLoading) isLoading$: Observable<boolean | null>;
  @Select(StorageMediumSelector.storageMedium) storageMedium$: Observable<Storage | null>;
  @Select(PositionGroupSelectors.selectedPositionGroups) selectedPositionGroups$: Observable<
    PositionGroup[]
  >;

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      match: MatchData;
      selectedPlayers: MatchData['player_matches'];
      sobValue: SOB['value'];
      seasonId: Season['id'];
      type?: string;
    },
    public dialogRef: MatDialogRef<AddPlayerDialogComponent>,
    private showmatchesService: ShowMatchesService,
    private cd: ChangeDetectorRef,
    private location: Location,
    private queryParamsService: QueryParamsService,
    private matchesService: ShowMatchesService
  ) {
    this.storageMedium$.pipe(takeUntil(this.unsubscribeAll)).subscribe((storageMedium: any) => {
      this.storageMedium = storageMedium;
    });
  }

  ngOnInit(): void {
    this.store.dispatch(new StartLoading());
    // this.store.dispatch(new PlayerMatch(this.data.match.id, this.data.match.role))
    this.matchesService
      .getPlayerMatch(this.data.match.id, this.data.match.role)
      .pipe(
        takeUntil(this.unsubscribeAll),
        finalize(() => this.store.dispatch(new StopLoading()))
      )
      .subscribe((res) => {
        this.data.selectedPlayers = res;
      });

    this.selectedPositionGroups$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((pgs: PositionGroup[]) => {
        this.listedPlayers = [];
        this.selectedPlayers = [];
        this.players = [];

        this.selectedSubstitution = undefined;
        this.substitutionList = [];

        if (pgs.length) this.positionGroupChange(pgs);
      });
  }

  positionGroupChange(pgs: PositionGroup[]): void {
    this.selectedPgs = pgs;

    this.store.dispatch(new StartLoading());
    forkJoin([
      this.showmatchesService.getPlayersByPg(
        this.selectedPgs.map((pg: PositionGroup) => pg.name),
        this.data.seasonId
      ),
      this.showmatchesService.getSubstitutionsByPg(
        this.data.seasonId,
        this.selectedPgs.map((pg: PositionGroup) => pg.name),
        this.data.sobValue
      ),
    ])
      .pipe(
        takeUntil(this.unsubscribeAll),
        finalize(() => {
          this.store.dispatch(new StopLoading());
        })
      )
      .subscribe(([players, substitutionList]) => {
        this.players = this.filterUniquePlayers(players);
        this.sortPlayersByNameAndJersey();
        this.setPlayersPositions();
        this.populateSelectedPlayers();

        this.substitutionList = substitutionList;

        this.cd.detectChanges();
      });
  }

  setPlayersPositions(): void {
    this.players.forEach((player: any) => {
      player.playerpositions = [];

      if (
        player.season_wise_pgs &&
        player.season_wise_pgs[this.data.seasonId] &&
        player.season_wise_pgs[this.data.seasonId].length
      ) {
        let pgIds = [
          ...new Set(
            player.season_wise_pgs[this.data.seasonId].map((pg: any) => pg.position_group)
          ),
        ];

        pgIds.forEach((pgId) => {
          let pg = this.selectedPgs.find((pg) => pg.name == pgId);
          if (pg) {
            player.playerpositions.push(...pg.positions);
          }
        });
      }
    });
  }

  filterUniquePlayers(players: Player[]): Player[] {
    return players.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  }

  sortPlayersByNameAndJersey(): void {
    this.players.sort((a, b) => {
      if (
        a.season_wise_player_detail[this.data.seasonId] &&
        b.season_wise_player_detail[this.data.seasonId]
      ) {
        return (
          a.season_wise_player_detail[this.data.seasonId]['jersey'] -
            b.season_wise_player_detail[this.data.seasonId]['jersey'] ||
          a.firstname.localeCompare(b.firstname)
        );
      }
    });
  }

  populateSelectedPlayers(): void {
    this.selectedPlayers = this.data.selectedPlayers.map((player) => ({
      jersey: player.jersey_no,
      match_id: this.data.match.id,
      player_id: player.player_id,
      position: player.position,
      position_name: player.position_name,
      position_group: player.position_group,
    }));

    this.players = this.players.map((player) => {
      player.checked = player.selected = player.disable = false;
      if (this.selectedPlayers.find((p) => p.player_id == player.id)) {
        player.checked = player.selected = player.disable = true;
      }

      return player;
    });

    this.listedPlayers = this.selectedPlayers
      .filter((player) => this.players.map((p) => p.id).includes(player.player_id))
      .filter((player) => this.selectedPgs.map((pg) => pg.name).includes(player.position_group))
      .map((player) => player);

    // if (this.data.type == 'ADD_DURING_MATCH') {
    //   let gradedPlayers = this.store
    //     .selectSnapshot(GradingSelectors.gradingData)
    //     .flatMap((group) => group.players.map((player) => player.player_id));

    //   this.listedPlayers = this.listedPlayers.filter((player) =>
    //     gradedPlayers.includes(player.player_id)
    //   );
    // }
  }

  substitutionChanged(substitution: PlayerSubstituion): void {
    this.listedPlayers = [];
    this.selectedPlayers = [];
    this.substitutionPlayers = [];

    if (substitution) {
      this.selectedPlayers = substitution.player_position
        .filter((player) =>
          this.selectedPgs.some(
            (pg: PositionGroup) => pg.name === player.position_info[0].postion_group
          )
        )
        .map((m) => {
          this.substitutionPlayers.push({
            id: m.player_info[0].id,
            jersey: m.player_info[0].jersey,
            firstname: m.player_info[0].firstname,
            lastname: m.player_info[0].lastname,
            position_id: m.position_info[0].id,
            positions: m.position_info[0].name,
            checked: true,
            selected: true,
            disable: true,
          } as any);

          return {
            jersey: m.player_info[0].jersey,
            match_id: this.data.match.id,
            player_id: m.player_info[0].id,
            position: m.position_info[0].id,
            position_name: m.position_info[0].name,
          };
        });

      this.listedPlayers = JSON.parse(JSON.stringify(this.selectedPlayers));
    } else {
      this.populateSelectedPlayers();
    }

    this.cd.detectChanges();
  }

  selectedPlayerChange(player) {
    this.players = this.players.map((p: Player) => {
      if (p.id == player.player_id) p.selected = true;
      return p;
    });
    this.listedPlayers.push(player);
    this.selectedPlayers.push(player);
  }

  removeListedPlayer(player, i: number) {
    this.listedPlayers.splice(i, 1);
    this.selectedPlayers.splice(
      _.findIndex(this.selectedPlayers, { player_id: player.player_id }),
      1
    );

    if (!!this.selectedSubstitution) {
      this.substitutionPlayers = this.substitutionPlayers.map((p: Player) => {
        if (p.id == player.player_id) {
          p.selected = false;
          p.disable = false;
          p.checked = false;
        }
        return p;
      });
    } else {
      this.players = this.players.map((p: Player) => {
        if (p.id == player.player_id) {
          p.selected = false;
          p.disable = false;
          p.checked = false;
        }
        return p;
      });
    }

    this.cd.detectChanges();
  }

  getPositionGroupIds(players, positionGroups) {
    return players
      .map((player) => {
        const foundPosition = positionGroups.find((positionGroup) =>
          positionGroup.positions.some((position) => position.id === player.position)
        );

        return foundPosition ? foundPosition.name : null;
      })
      .filter(Boolean); // Filter out null values
  }

  getSelectedPlayerIds() {
    return this.listedPlayers.map((player) => player.player_id);
  }

  startGrading(): void {
    if (this.data.type == 'ADD_DURING_MATCH') {
      this.store.dispatch(
        new UpdateGradingVisiblePgs(
          this.getPositionGroupIds(this.selectedPlayers, this.selectedPgs)
        )
      );

      // this.store.dispatch(new UpdateGradingVisiblePlayers(this.getSelectedPlayerIds()));
    } else {
      this.store.dispatch(
        new SetGradingVisiblePgs(this.getPositionGroupIds(this.selectedPlayers, this.selectedPgs))
      );

      // this.store.dispatch(new SetGradingVisiblePlayers(this.getSelectedPlayerIds()));
    }

    this.store.dispatch(new StartLoading());
    this.matchesService
      .setPlayerMatch(this.listedPlayers)
      .pipe(
        takeUntil(this.unsubscribeAll),
        finalize(() => this.store.dispatch(new StopLoading()))
      )
      .subscribe((res) => {
        this.dialogRef.close(true);

        if (this.data.type == 'ADD_DURING_MATCH') {
          this.store.dispatch(new RefreshGradingScreen(true));
        } else {
          this.store.dispatch(new SetGradingBackLink(this.location.path()));

          this.queryParamsService.updateQueryParams(
            {
              count: null,
              gp_count: null,
              matchType: null,
              weekNo: null,
              mode: 'v',
              activity: 'g',
            },
            ['/grading', 'grade-controller', res[0].match_id]
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
}
