import { Injectable } from '@angular/core';
import { State, Action, StateContext, Select } from '@ngxs/store';
import { GradingData, GradingMatch } from '../interfaces/grading.interface';
import {
  GetGradingData,
  GetGradingMatch,
  GradingDeleteAllPlayers,
  GradingDeletePlayer,
  GradingSwapPlayer,
  RefreshGradingScreen,
  RemoveGradingVisiblePgs,
  ResetGradingData,
  ResetGradingState,
  SetGradingBackLink,
  SetGradingVisibleMatches,
  SetGradingVisiblePgs,
  UpdateGradingData,
  UpdateGradingMatch,
  UpdateGradingPgCheck,
  UpdateGradingPlayer,
  UpdateGradingPlayerCheck,
  UpdateGradingPlayerInStore,
  UpdateGradingPlayerScore,
  UpdateGradingPlayerSkillClass,
  UpdateGradingVisiblePgs,
} from './grading.actions';
import { Observable, switchMap, tap } from 'rxjs';
import { GradingService } from '../services/grading.service';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { StorageMediumSelector } from '@shared/states/storage-medium/storage-medium.selector';
import {
  MatchData,
  PlayerMatchI,
} from 'app/modules/show-matches/interfaces/show-matches.interface';
import { ResetSkillClasses } from '@shared/states/skill-class-filter/skill-class-filter.action';

export interface GradingStateModel {
  gradingMatch: GradingMatch;
  gradingData: GradingData[];
  refreshGradingScreen: boolean;
}

@State<GradingStateModel>({
  name: 'grading',
  defaults: {
    gradingMatch: null,
    gradingData: [],
    refreshGradingScreen: false,
  },
})
@Injectable()
export class GradingState {
  storageMedium: Storage;
  @Select(StorageMediumSelector.storageMedium) storageMedium$: Observable<Storage | null>;

  constructor(private gradingService: GradingService) {
    this.storageMedium$.subscribe((storageMedium: any) => {
      this.storageMedium = storageMedium;
    });
  }

  syncDataWithStorage(matchData: MatchData, gradingData: GradingData[]) {
    let gradingState = JSON.parse(this.storageMedium.getItem('gradingState'));
    let tempGradingData: GradingData[] = [];

    gradingState?.gradingData.forEach((storageData: GradingData) => {
      let apiResponseData = gradingData.find((data) => data.name == storageData.name);
      if (apiResponseData) {
        apiResponseData.checked = storageData.checked;
        apiResponseData.expanded = storageData.expanded;

        //maintaining the sorting state
        tempGradingData.push({ ...apiResponseData });
        tempGradingData[tempGradingData.length - 1].players = [];
      }

      storageData.players.forEach((storagePlayer) => {
        let apiResponsePlayer = apiResponseData?.players.find(
          (player) => player.player_id == storagePlayer.player_id
        );
        if (apiResponsePlayer) {
          apiResponsePlayer.checked = storagePlayer.checked;
          apiResponsePlayer.expanded = storagePlayer.expanded;
          tempGradingData[tempGradingData.length - 1].players.push(apiResponsePlayer);
        }
      });

      if (!tempGradingData[tempGradingData.length - 1]?.players.length) {
        tempGradingData.pop();
      }
    });

    gradingData.forEach((data: GradingData) => {
      let gd = tempGradingData.find((d) => d.name == data.name);
      if (gd) {
        data.players.forEach((player: PlayerMatchI) => {
          if (!gd.players.find((p) => p.id == player.id)) {
            gd.players.push(player);
          }
        });
      } else {
        tempGradingData.push(data);
      }
    });

    this.setDataToStorage(matchData, tempGradingData.length ? tempGradingData : gradingData);
  }

  setDataToStorage(matchData: MatchData, gradingData: GradingData[]): void {
    let gradingState = JSON.parse(this.storageMedium.getItem('gradingState'));

    this.storageMedium.setItem(
      'gradingState',
      JSON.stringify({
        matchData: matchData,
        gradingData: gradingData,
      })
    );
  }

  @Action(RefreshGradingScreen)
  refreshGradingScreen(ctx: StateContext<GradingStateModel>, { state }: RefreshGradingScreen) {
    ctx.patchState({
      refreshGradingScreen: state,
    });
  }

  @Action(GetGradingMatch)
  getGradingMatch(ctx: StateContext<GradingStateModel>, { matchId }: GetGradingMatch) {
    return this.gradingService.getGradingMatch(matchId).pipe(
      tap((matchData: GradingMatch) => {
        ctx.patchState({
          gradingMatch: { ...matchData },
        });
      })
    );
  }

  @Action(UpdateGradingMatch)
  updateGradingMatch(ctx: StateContext<GradingStateModel>, { match }: UpdateGradingMatch) {
    this.setDataToStorage(match, ctx.getState().gradingData);

    ctx.patchState({
      gradingMatch: match,
    });
  }

  @Action(GetGradingData)
  getGradingData(ctx: StateContext<GradingStateModel>, { match, playerId }: GetGradingData) {
    return this.gradingService.getGradingData(match, playerId).pipe(
      tap((gradingData: GradingData[]) => {
        this.syncDataWithStorage(match, gradingData);

        ctx.patchState({
          gradingData: [...JSON.parse(this.storageMedium.getItem('gradingState')).gradingData],
        });
      })
    );
  }

  @Action(UpdateGradingData)
  updateGradingData(ctx: StateContext<GradingStateModel>, { gradingData }: UpdateGradingData) {
    this.setDataToStorage(ctx.getState().gradingMatch, gradingData);

    ctx.patchState({
      gradingData: [...gradingData],
    });
  }

  @Action(GradingSwapPlayer)
  gradingSwapPlayer(ctx: StateContext<GradingStateModel>, { player, payload }: GradingSwapPlayer) {
    return this.gradingService.gradingSwapPlayer(player, payload).pipe(
      tap((res: PlayerMatchI) => {
        let gradingData = ctx.getState().gradingData;
        let playerPg = gradingData.find((data: GradingData) => data.name == player.position_group);
        playerPg.players = playerPg.players.map((p: PlayerMatchI) => {
          if (p.player_id == player.player_id) {
            res.checked = player.checked;
            res.expanded = player.expanded ?? false;
            return res;
          }

          return p;
        });

        this.setDataToStorage(ctx.getState().gradingMatch, gradingData);

        ctx.patchState({
          gradingData: [...gradingData],
        });
      })
    );
  }

  @Action(GradingDeletePlayer)
  gradingDeletePlayer(
    ctx: StateContext<GradingStateModel>,
    { playerId, playerPg }: GradingDeletePlayer
  ) {
    return this.gradingService.deletePlayer(playerId).pipe(
      tap(() => {
        let gradingData = ctx.getState().gradingData;
        gradingData = gradingData.filter((data: GradingData) => {
          if (data.name == playerPg) {
            data.players = data.players.filter((player: PlayerMatchI) => player.id != playerId);
          }
          if (data.players.length) return data;
        });

        this.setDataToStorage(ctx.getState().gradingMatch, gradingData);

        ctx.patchState({
          gradingData: [...gradingData],
        });
      })
    );
  }

  @Action(GradingDeleteAllPlayers)
  gradingDeleteAllPlayers(
    ctx: StateContext<GradingStateModel>,
    { players, playersPg }: GradingDeleteAllPlayers
  ) {
    return this.gradingService.deleteAllPlayers(players).pipe(
      tap(() => {
        let gradingData = ctx.getState().gradingData;
        gradingData = gradingData.filter((data: GradingData) => data.name != playersPg);

        this.setDataToStorage(ctx.getState().gradingMatch, gradingData);

        ctx.patchState({
          gradingData: [...gradingData],
        });
      })
    );
  }

  @Action(UpdateGradingPgCheck)
  updateGradingPgCheck(ctx: StateContext<GradingStateModel>, { grade }: UpdateGradingPgCheck) {
    let gradingData = ctx.getState().gradingData;
    gradingData = gradingData.map((data: GradingData) => {
      if (data.name == grade.name) {
        data.checked = !data.checked;
        data.players = data.players.map((player: PlayerMatchI) => {
          player.checked = data.checked;
          return player;
        });
      }

      return data;
    });

    this.setDataToStorage(ctx.getState().gradingMatch, gradingData);
    // ctx.dispatch(new SetGradingVisiblePgs(gradingData.filter((d) => d.checked).map((d) => d.name)));
    ctx.dispatch(
      new UpdateGradingVisiblePgs(gradingData.filter((d) => d.checked).map((d) => d.name))
    );

    ctx.patchState({
      gradingData: gradingData,
    });
  }

  @Action(UpdateGradingPlayerCheck)
  updateGradingPlayerCheck(
    ctx: StateContext<GradingStateModel>,
    { grade, index }: UpdateGradingPlayerCheck
  ) {
    let gradingData = ctx.getState().gradingData;

    let checked = false;
    grade.players.forEach((player: PlayerMatchI) => {
      if (player.checked) checked = true;
    });

    grade.checked = checked;

    gradingData = gradingData.map((data: GradingData) => {
      if (data.name == grade.name) return grade;
      else return data;
    });

    this.setDataToStorage(ctx.getState().gradingMatch, gradingData);
    // ctx.dispatch(new SetGradingVisiblePgs(gradingData.filter((d) => d.checked).map((d) => d.name)));
    ctx.dispatch(
      new UpdateGradingVisiblePgs(gradingData.filter((d) => d.checked).map((d) => d.name))
    );

    ctx.patchState({
      gradingData: gradingData,
    });
  }

  @Action(SetGradingBackLink)
  setGradingBackLink(ctx: StateContext<GradingStateModel>, { link }: SetGradingBackLink) {
    this.storageMedium.setItem('backlink', link);
  }

  @Action(SetGradingVisibleMatches)
  setGradingVisibleMatches(
    ctx: StateContext<GradingStateModel>,
    { visibleMatches }: SetGradingVisibleMatches
  ) {
    this.storageMedium.setItem('visibleMatches', JSON.stringify([...new Set(visibleMatches)]));
  }

  @Action(SetGradingVisiblePgs)
  setGradingVisiblePgs(ctx: StateContext<GradingStateModel>, { pgs }: SetGradingVisiblePgs) {
    this.storageMedium.removeItem('visiblePgs');
    this.storageMedium.setItem('visiblePgs', JSON.stringify([...new Set(pgs)]));
  }

  @Action(UpdateGradingVisiblePgs)
  UpdateGradingVisiblePgs(ctx: StateContext<GradingStateModel>, { pgs }: UpdateGradingVisiblePgs) {
    let visiblePgs = JSON.parse(this.storageMedium.getItem('visiblePgs'));
    this.storageMedium.setItem('visiblePgs', JSON.stringify([...new Set(pgs.concat(visiblePgs))]));
  }

  @Action(RemoveGradingVisiblePgs)
  removeGradingVisiblePgs(ctx: StateContext<GradingStateModel>) {
    this.storageMedium.removeItem('visiblePgs');
  }

  @Action(UpdateGradingPlayerInStore)
  updateGradingPlayerInStore(
    ctx: StateContext<GradingStateModel>,
    { playerMatchId }: UpdateGradingPlayerInStore
  ) {
    return this.gradingService.getGradingPlayerMatch(playerMatchId).pipe(
      tap((res: PlayerMatchI) => {
        let gradingData = ctx.getState().gradingData;
        gradingData.forEach((data: GradingData) => {
          data.players = data.players.map((player: PlayerMatchI) => {
            if (player.id == playerMatchId) {
              res.checked = player.checked;
              res.expanded = player.expanded;
              return res;
            }
            return player;
          });
        });

        this.setDataToStorage(ctx.getState().gradingMatch, gradingData);

        ctx.patchState({
          gradingData: [...gradingData],
        });
      })
    );
  }

  @Action(UpdateGradingPlayerScore)
  updateGradingPlayerScore(
    ctx: StateContext<GradingStateModel>,
    { skill, payload }: UpdateGradingPlayerScore
  ) {
    return this.gradingService.setGradingPlayerScore(skill.id, payload).pipe(
      switchMap(() => {
        return this.gradingService.getGradingPlayerMatch(skill.player_match_id).pipe(
          tap((res: PlayerMatchI) => {
            let gradingData = ctx.getState().gradingData;
            gradingData.forEach((data: GradingData) => {
              data.players = data.players.map((player: PlayerMatchI) => {
                if (player.id == skill.player_match_id) {
                  res.checked = player.checked;
                  res.expanded = player.expanded;
                  return res;
                }
                return player;
              });
            });

            this.setDataToStorage(ctx.getState().gradingMatch, gradingData);

            ctx.patchState({
              gradingData: gradingData,
            });
          })
        );
      })
    );
  }

  @Action(UpdateGradingPlayerSkillClass)
  updateGradingPlayerSkillClass(
    ctx: StateContext<GradingStateModel>,
    { score, payload }: UpdateGradingPlayerSkillClass
  ) {
    return this.gradingService.addSkillClasstoSkillScore(score.id, payload);
  }

  @Action(UpdateGradingPlayer)
  updateGradingPlayer(
    ctx: StateContext<GradingStateModel>,
    { playerMatchId, payload }: UpdateGradingPlayer
  ) {
    return this.gradingService.gradingUpdatePlayer(playerMatchId, payload).pipe(
      tap((res: PlayerMatchI) => {
        let gradingData = ctx.getState().gradingData;
        gradingData.forEach((data: GradingData) => {
          data.players = data.players.map((player: PlayerMatchI) => {
            if (player.id == playerMatchId) {
              res.checked = player.checked;
              res.expanded = player.expanded;
              return res;
            }
            return player;
          });
        });

        this.setDataToStorage(ctx.getState().gradingMatch, gradingData);

        ctx.patchState({
          gradingData: [...gradingData],
        });
      })
    );
  }

  @Action(ResetGradingData)
  resetGradingData(ctx: StateContext<GradingStateModel>) {
    ctx.patchState({
      gradingData: [],
    });
  }

  @Action(ResetGradingState)
  resetGradingState(ctx: StateContext<GradingStateModel>) {
    this.storageMedium.removeItem('backlink');
    this.storageMedium.removeItem('visiblePgs');
    this.storageMedium.removeItem('visibleMatches');
    this.storageMedium.removeItem('gradingState');
    this.storageMedium.removeItem('videoData');

    ctx.dispatch(new ResetSkillClasses());

    ctx.setState({
      gradingMatch: null,
      gradingData: [],
      refreshGradingScreen: false,
    });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<GradingStateModel>) {
    this.storageMedium.removeItem('backlink');
    this.storageMedium.removeItem('visiblePgs');
    this.storageMedium.removeItem('visibleMatches');
    this.storageMedium.removeItem('gradingState');
    this.storageMedium.removeItem('videoData');

    ctx.dispatch(new ResetSkillClasses());

    ctx.setState({
      gradingMatch: null,
      gradingData: [],
      refreshGradingScreen: false,
    });
  }
}
