import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  AppendSeason,
  DeleteSeason,
  EditSeasonDescription,
  GetSeasons,
  SelectedSeason,
  SetDefaultSeason,
  UpdateSelectedSeasonsForDeletion,
} from './season.action';
import { Season } from '@shared/interfaces/season.interface';
import { catchError, iif, of, tap } from 'rxjs';
import { SeasonService } from '@shared/services/season/season.service';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';

export interface SeasonStateModel {
  currentSeason: {
    year: number;
    id: number;
    user_id: number;
    name: string;
    default_season: boolean;
  };
  allSeasons: Season[];
  allSelectedSeasonForDeletion: Season[];
}

@State<SeasonStateModel>({
  name: 'season',
  defaults: {
    currentSeason: {
      year: null,
      id: null,
      user_id: null,
      name: null,
      default_season: false,
    },
    allSeasons: [],
    allSelectedSeasonForDeletion: [],
  },
})
@Injectable()
export class SeasonState {
  constructor(private seasonService: SeasonService) {}

  @Action(SelectedSeason)
  selectedSeason({ patchState }: StateContext<SeasonStateModel>, { payload }: SelectedSeason) {
    patchState({ currentSeason: { ...payload } });
  }

  @Action(AppendSeason)
  appendSeason(
    { patchState, getState }: StateContext<SeasonStateModel>,
    { payload }: AppendSeason
  ) {
    if (payload) patchState({ allSeasons: [...getState().allSeasons, payload] });
  }

  @Action(DeleteSeason)
  deleteSeason(
    { patchState, getState }: StateContext<SeasonStateModel>,
    { payload }: DeleteSeason
  ) {
    let allSeasons = getState().allSeasons.filter((season: Season) => season.id != payload.id);
    if (payload) patchState({ allSeasons: allSeasons });
  }

  @Action(EditSeasonDescription)
  editSeasonDescription(
    { patchState, getState }: StateContext<SeasonStateModel>,
    { payload }: EditSeasonDescription
  ) {
    let allSeasons = getState().allSeasons;
    allSeasons = allSeasons.map((season: Season) => {
      if (season.id == payload.id) {
        season.name = payload.name;
      }
      return season;
    });
    if (payload) patchState({ allSeasons: allSeasons });
  }

  @Action(SetDefaultSeason)
  setDefaultSeason(
    { patchState, getState }: StateContext<SeasonStateModel>,
    { payload }: SetDefaultSeason
  ) {
    let allSeasons = getState().allSeasons;
    allSeasons = allSeasons.map((season: Season) => {
      season.default_season = season.id == payload.id;
      return season;
    });
    if (payload) patchState({ allSeasons: allSeasons });
  }

  @Action(GetSeasons)
  getSeasons({ getState, patchState }: StateContext<SeasonStateModel>) {
    return iif(
      () => !!getState().allSeasons.length,
      of(getState().allSeasons),
      this.seasonService.getSeasons().pipe(
        tap((seasons: Season[]) => {
          let sortedSeason = seasons.sort((a, b) => a.year - b.year);
          if (!getState().currentSeason.id) {
            let currentSeason = sortedSeason.find((season) => season.default_season) || seasons[0];
            patchState({
              currentSeason,
            });
          }

          patchState({
            allSeasons: sortedSeason,
          });
          return sortedSeason;
        }),
        catchError((error) => {
          return of(error);
        })
      )
    );
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<SeasonStateModel>) {
    ctx.setState({
      currentSeason: {
        year: null,
        id: null,
        user_id: null,
        name: null,
        default_season: false,
      },
      allSeasons: [],
      allSelectedSeasonForDeletion: [],
    });
  }

  @Action(UpdateSelectedSeasonsForDeletion)
  updateSelectedSeasonsForDeletion(
    ctx: StateContext<SeasonStateModel>,
    action: UpdateSelectedSeasonsForDeletion
  ) {
    const state = ctx.getState();
    let newSelections = [...state.allSelectedSeasonForDeletion];

    action.seasons.forEach((season) => {
      const seasonIndex = newSelections.findIndex((s) => s.id === season.id);
      if (seasonIndex > -1) {
        newSelections.splice(seasonIndex, 1);
      } else {
        newSelections.push(season);
      }
    });

    ctx.patchState({
      allSelectedSeasonForDeletion: newSelections,
    });
  }
}
