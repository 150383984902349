<mat-button-toggle-group class="ml-[12px]" [(ngModel)]="selectedSob" #sob="matButtonToggleGroup">
  <mat-button-toggle
    *ngFor="let sob of sobs"
    [value]="sob.value"
    [disabled]="selectedUrl.includes('depth-chart') && sob.value === 'ST'"
    matTooltip="{{
      selectedUrl.includes('depth-chart') && sob.value === 'ST'
        ? 'Depth Chart on Special Teams is Coming Soon...'
        : ''
    }}"
    (change)="sobChange(sob)"
    ><b> {{ showAbbreviated ? sob.value : sob.name }}</b>
  </mat-button-toggle>
</mat-button-toggle-group>
