export enum Permission {
  ViewInfiniteAthlete = 'can_view_infinite_data',
  // ViewCatapultMapping = 'view_catapult_mapping',
  ChangeCoach = 'change_coach',
  ViewPgAll = 'view_pg_all',
  DeleteMatches = 'delete_matches',
  ViewPlayerProductionReport = 'view_player_production_report',
  ViewTeamProductionReport = 'view_team_production_report',
  ChangeMtpTypes = 'change_mtp_types',
  ViewPgOffense = 'view_pg_offense',
  ViewPgDefense = 'view_pg_defense',
  ViewPgSpecialTeams = 'view_pg_specialteams',
  AddReports = 'add_reports',
  AddSeason = 'add_season',
  DeleteReports = 'delete_reports',
  AddMtpTypes = 'add_mtp_types',
  DeletePositionGroup = 'delete_positiongroup',
  DeleteSeason = 'delete_season',
  ViewGridironLeadersReport = 'view_gridiron_leaders_report',
  ApplyPgFilterPlayByPlay = 'apply_pg_filter_playbyplay',
  ViewMtpTypes = 'view_mtp_types',
  ChangePosition = 'change_position',
  AddCoach = 'add_coach',
  ChangeSubstitution = 'change_substitution',
  DeleteOverallGrade = 'delete_overall_grade',
  DeleteMtpTypes = 'delete_mtp_types',
  DeletePlayerMatch = 'delete_player_match',
  ApplyCustomFilter = 'apply_custom_filter',
  ViewPlayerMatch = 'view_player_match',
  ViewPlayerReport = 'view_player_report',
  AddWeek = 'add_week',
  ViewPlayerPositionSubstitution = 'view_playerpositionsubstitution',
  ChangeSeason = 'change_season',
  ViewCoach = 'view_coach',
  AddPlayerMatch = 'add_player_match',
  ChangePositionGroup = 'change_positiongroup',
  ViewPositionGroupReport = 'view_position_group_report',
  ViewSeason = 'view_season',
  DeletePosition = 'delete_position',
  DeleteCoach = 'delete_coach',
  AddMatches = 'add_matches',
  AddPosition = 'add_position',
  AddPlayerPositionSubstitution = 'add_playerpositionsubstitution',
  DeleteOpponent = 'delete_opponent',
  ChangePlayerMatch = 'change_player_match',
  AddPositionGroup = 'add_positiongroup',
  ChangeWeek = 'change_week',
  ChangeMatches = 'change_matches',
  ViewPlayerComparisonReport = 'view_player_comparison_report',
  ViewOverallGrade = 'view_overall_grade',
  ApplyPlayersFilter = 'apply_players_filter',
  AddOpponent = 'add_opponent',
  ViewGradingReport = 'view_grading_report',
  DeletePlayerPositionSubstitution = 'delete_playerpositionsubstitution',
  DownloadCoachReport = 'download_coach_report',
  ViewMatches = 'view_matches',
  ViewWeek = 'view_week',
  ChangeOpponent = 'change_opponent',
  ViewOpponent = 'view_opponent',
  ChangeOverallGrade = 'change_overall_grade',
  DeleteSubstitution = 'delete_substitution',
  ViewPositionGroup = 'view_positiongroup',
  ViewEfficiencyReport = 'view_efficiency_report',
  ViewScoutingReport = 'view_scouting_report',
  DeletePlayer = 'delete_player',
  DeleteWeek = 'delete_week',
  ViewReports = 'view_reports',
  ChangePlayer = 'change_player',
  ChangeReports = 'change_reports',
  AddOverallGrade = 'add_overall_grade',
  AddPlayer = 'add_player',
  ApplySituationalFilterGradingReport = 'apply_situational_filter_grading_report',
  ViewPosition = 'view_position',
  ChangePlayerPositionSubstitution = 'change_playerpositionsubstitution',
  ApplyReportTypeGradingReport = 'apply_report_type_grading_report',
  AddSubstitution = 'add_substitution',
  ViewPlayer = 'view_player',
  ViewSubstitution = 'view_substitution',
  ViewScoutingReports = 'view_scouting_report',
  ViewSkillClass = 'view_skillclass',
  RunPassTendencies = 'view_run_pass_tendency_report',

  ViewOffenseOfficialDepthChart = 'view_offense_official_depth_chart',
  ChangeOffenseOfficialDepthChart = 'change_offense_official_depth_chart',

  ViewOffenseMockDepthChart = 'view_offense_mock_depth_chart',
  ChangeOffenseMockDepthChart = 'change_offense_mock_depth_chart',

  ViewOffenseScoutTeamDepthChart = 'view_offense_scout_team_depth_chart',
  ChangeOffenseScoutTeamDepthChart = 'change_offense_scout_team_depth_chart',

  ViewDefenseOfficialDepthChart = 'view_defense_official_depth_chart',
  ChangeDefenseOfficialDepthChart = 'change_defense_official_depth_chart',

  ViewDefenseMockDepthChart = 'view_defense_mock_depth_chart',
  ChangeDefenseMockDepthChart = 'change_defense_mock_depth_chart',

  ViewDefenseScoutTeamDepthChart = 'view_defense_scout_team_depth_chart',
  ChangeDefenseScoutTeamDepthChart = 'change_defense_scout_team_depth_chart',

  ViewSpecialTeamsOfficialDepthChart = 'view_specialteams_official_depth_chart',
  ChangeSpecialTeamsOfficialDepthChart = 'change_specialteams_official_depth_chart',

  ViewSpecialTeamsMockDepthChart = 'view_specialteams_mock_depth_chart',
  ChangeSpecialTeamsMockDepthChart = 'change_specialteams_mock_depth_chart',

  ViewSpecialTeamsScoutTeamDepthChart = 'view_specialteams_scout_team_depth_chart',
  ChangeSpecialTeamsScoutTeamDepthChart = 'change_specialteams_scout_team_depth_chart',
}
