import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { ClearAllStates } from '@NgXs/authentication/actions/clear-all-state.action';
import { DepthChartService, DepthChartTypes } from '../depth-chart.service';
import { DepthChartSelectionChange } from './depth-chart.action';

export interface DepthChartStateModel {
  selectedDepthChart: string;
}

@State<DepthChartStateModel>({
  name: 'depthChart',
  defaults: {
    selectedDepthChart: DepthChartTypes.official,
  },
})
@Injectable()
export class DepthChartState {
  constructor(private depthChartService: DepthChartService) {}

  @Action(DepthChartSelectionChange)
  DepthChartSelectionChange(ctx: StateContext<DepthChartStateModel>, { selectedDepthChart }) {
    ctx.patchState({ selectedDepthChart: selectedDepthChart });
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<DepthChartStateModel>) {
    ctx.setState({
      selectedDepthChart: DepthChartTypes.official,
    });
  }
}
